import '../css/superior.css'
import { IoMdMenu } from "react-icons/io";

function Superior() {
  return (
    <div className='MenuSuperior'>
      <hr className='linha' />
      <div className='SuperiorItem'>
        <img 
          src="https://orionsa.com.br/wp-content/uploads/2022/03/logo_vermelho.png"
          alt="Logo Orion SA"
        />
        <span className='menuIcon'><IoMdMenu /></span>
        <div className='menu'>
          <span> Sobre</span>
          <span> Produtos</span>
          <span> Representantes</span>
          <span> Blog</span>
          <span> Contato</span>
          <span> Orçamento</span>
        </div>
      </div>
      <hr className='linha' />
    </div>

  );
}

export default Superior;