import '../css/banner.css'
import lateralImg from '../assets/lateral.png';
import writer from '../assets/writer.png';

function Banner() {
    return (
        <div className='Banner'>
            <div className='Lateral'>
                <img src={lateralImg} alt='lateral vermelha' />
            </div>
            <div className='Writer'>
                <img src={writer} alt='writer' />
            </div>
            
        </div>
    );
}

export default Banner;
