import React, { useState } from 'react';
import { FaNewspaper } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Verificar from '../services/consultaApi'; // Corrigidoss
import '../css/buscarLaudo.css';
import ReactGA from 'react-ga4';
import { Grid  } from 'react-loader-spinner';



function BuscarLaudo() {
  const [selectedOption, setSelectedOption] = useState('serieEpi');
  const [inputBusca, setInputBusca] = useState('');
  const [laudos, setLaudos] = useState([]); // Corrigido
  const [loading, setLoading] = useState(false);//carregamento do simbolo de loading...

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
    setInputBusca('');
  };

  const handleInputChange = (event) => {
    setInputBusca(event.target.value);
  };

  const handleVerificar = async () => {
    setLoading(true);  // Inicia o carregamento
    try {
      const data = await Verificar(inputBusca, selectedOption);  // Chama sua função de busca

      if (data) {
        setLaudos(data);  // Armazena os dados recebidos
        window.scrollTo({ bottom: 100, behavior: 'smooth' });  // Scroll para baixo suavemente
      }
      
      ReactGA.event({
        category: 'Buscando laudo',
        action: 'Clicado no Botão',
        label: 'Verificar'
      });
    } catch (error) {
      
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);  // Finaliza o carregamento após a resposta
    }
  };


  return (
    <div className="BuscarLaudo">
      <h2>Laudos Técnicos</h2>
      <hr className="linha" />
      <p>Opções de pesquisa (somente uma opção é selecionável)</p>
      <div>
        <input
          type="checkbox"
          id="numerolaudo"
          className="numerolaudo"
          value="numerolaudo"
          checked={selectedOption === 'numerolaudo'}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="numerolaudo">Número do laudo</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="serieEpi"
          className="serieEpi"
          value="serieEpi"
          checked={selectedOption === 'serieEpi'}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="serieEpi">N° de série do EPI</label>
      </div>
      <div className="containerBusca">
        <input
          type="text"
          id="inputNumero"
          className="inputNumero"
          value={inputBusca}
          placeholder={selectedOption === 'numerolaudo' ? 'Digite o número do Laudo' : 'Digite o número de Série do EPI'}
          onChange={handleInputChange}
        />
        <input
          type="button"
          id="verificar"
          className="verificar"
          onClick={handleVerificar}
          value="Verificar"
        />
      </div>
      <div className="gridContainer">
        <div className="gridLaudoHeader">
          <span>OF</span>
          <span>Mão Esquerda</span>
          <span>Mão Direita</span>
          <span>Produto</span>
          <span className='descPro'>Descrição do Produto</span>
          <span>Visualizar</span>
        </div>
        {loading ? (  // Exibe o spinner durante o carregamento
          <div className="spinner">
          <Grid 
            height="50"
            width="50"
            color="#00BFFF"
            ariaLabel="loading"
          />
          </div>
        ) : laudos.length > 0 ? (  // Exibe os laudos quando os dados estão disponíveis
          laudos.map((laudo, index) => (
            <div className="gridLaudo" key={index}>
              <span>{laudo.numlib}</span>
              <span>{laudo.numser}</span>
              <span>{laudo.numsec}</span>
              <span>{laudo.codpro_1}</span>
              <span className="descPro">{laudo.despro_1} {laudo.despro_2}</span>
              <span>
                <Link to={{
                  pathname: `/certificado/${laudo.numser}`
                }}>
                  <FaNewspaper className="iconVisualizar" />
                </Link>
              </span>
            </div>
          ))
        ) : (
          <div className="gridLaudo">
            <span>Nenhum laudo encontrado.</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default BuscarLaudo;


