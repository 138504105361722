import React, { useState, useEffect } from 'react';
import '../../css/pdflaudo.css';
import { useParams } from 'react-router-dom';
import Verificar from '../../services/consultaApi'; // Supondo que este seja o caminho correto
import logo from '../../assets/logo_vermelho.png';
import carimbo from '../../assets/carimbo.png';
import assinatura from '../../assets/assinatura.png';
import { IoIosPrint } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";



function PdfLaudo() {

    const [tipoProduto, setTipoProduto] = useState(0); // 0 para luvas, 1 para mangas
    const [montaLaudos, setMontaLaudos] = useState([]);
    const params = useParams();

    const handlePrint = () => {
        window.print();
    };
    const handleBack = () => {
        window.history.back();
    };

    useEffect(() => {
        const handleMontaLaudo = async (numser) => {
            const data = await Verificar(numser, 'serieEpi');
            if (data) {
                setMontaLaudos(data);
                // Verifica o valor de codPro_1 e atualiza o tipoProduto
                if (data[0].codpro_1.startsWith('03')) {
                    setTipoProduto(0); // Luvas
                } else if (data[0].codpro_1.startsWith('50')) {
                    setTipoProduto(1); // Mangas
                }
            }
        };

        if (params.numser) {
            handleMontaLaudo(params.numser);
        }
    }, [params.numser]); // Executa sempre que params.numser mudar

    if (montaLaudos.length === 0) {
        return null; // Renderiza null ou outra coisa enquanto montaLaudos está vazio
    }

    // Agora é seguro acessar montaLaudos[0].numser

    const tipos = [
        {
            nome: "LUVAS",
            norma: "NBR 10622/89 e ASTM D 120",
            form:"FR 370 028 00",
            unidade:'PR'
        },
        {
            nome: "MANGAS",
            norma: "NBR 10623/1989 e ASTM D 1051",
            form:"FR 540 001 00",
            unidade:'CJ'
        }
    ];//tipos de certificados

    return (
        <div>
            <div class="top-bar">
                <IoArrowBack onClick={handleBack } className="icon-button" />
                <div className='barTop'> VCO 1.0</div>
                <IoIosPrint onClick={handlePrint} className="icon-button" />
            </div>
            <div className='page' size="A4" layout="portrait">

                <div className="certificado-container" >
                    <div className='logo'><img src={logo} alt='logo orion'></img>
                     </div>
                    <h1>CERTIFICADO DE QUALIDADE {tipos[tipoProduto].nome} ISOLANTES</h1>
                    <div className="certificado-header">
                        <div className="header-row">
                            <p>LOCALIZAÇÃO: Rodovia Presidente Dutra, Km 135,1 - SP</p>
                            <p className="date">Data de Fabricação: {new Date(montaLaudos[0].datumv).toLocaleDateString('pt-BR')}</p>
                        </div>
                        <table className="header-table">
                            <tbody>
                                <tr>
                                    <td className="of-number">OF</td>
                                    <td className="serial-number">{montaLaudos[0].numlib}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="serial-table">

                            <tbody>
                                <tr>
                                    <td rowSpan={2}><b>Número de Série</b></td>
                                    <td><b>Mão Esquerda</b></td>
                                    <td><b>Mão Direita</b></td>

                                </tr>
                                <tr>

                                    <td>{montaLaudos[0].numser}</td>
                                    <td><span>{montaLaudos[0].numsec}</span></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="certificado-body">
                        <p>
                            Certificamos que as luvas isolantes relacionadas abaixo, foram submetidas a Inspeção Visual, Inspeção Dimensional e Teste Elétrico, conforme norma {tipos[tipoProduto].norma}, e na data de fabricação encontram-se aprovadas.
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>PRODUTO</th>
                                    <th>DESCRIÇÃO</th>

                                    <th>QTDE.</th>
                                    <th>UN</th>
                                    <th>TENSÃO APLICADA PARA TESTE (VOLTS)</th>
                                    <th>TENSÃO MÁXIMA DE USO (VOLTS)</th>
                                    <th>TEMPO TESTE (MIN.)</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{montaLaudos[0].codpro_1}</td>
                                    <td>{montaLaudos[0].despro_1} {montaLaudos[0].despro_2}</td>

                                    <td>1,000</td>
                                    <td>{tipos[tipoProduto].unidade}</td>
                                    <td>{montaLaudos[0].cf2inp}</td>
                                    <td>{montaLaudos[0].cf1inp}</td>
                                    <td>{montaLaudos[0].cf3inp}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="certificado-footer">
                        <h3>Transporte e Armazenamento:</h3>
                        <ul>
                            <li>Mantenha os produtos em embalagem e disposições originais quando guardados;</li>
                            <li>Não dobrar, comprimir ou esticar excessivamente os equipamentos de proteção;</li>
                            <li>Mantenha em local livre de produtos químicos, vapores prejudiciais, descargas elétricas e afastada de qualquer fonte de calor;</li>
                            <li>Mantenha em locais com temperatura inferior a 35ºC.</li>
                        </ul>
                        <h3>Garantia:</h3>
                        <p>
                            A ORION substitui os produtos não utilizados que em qualquer ocasião durante determinado período da data de recebimento do lote, deixem de ser aprovados nos ensaios citados na norma. Esta garantia é válida somente se os produtos tiverem sido armazenados conforme descrito e não tiverem sido submetidos a mais do que um ensaio de recebimento original e um reensaio. O período de garantia dos produtos isolantes ORION é de 9 meses.
                        </p>
                        <h3>Recomendações:</h3>
                        <p>
                            A ORION recomenda ensaios de revalidação a cada 6 meses para produtos em uso e a cada 12 meses (no máximo) para produtos armazenados.
                        </p>
                        <div className='carimboAssinatura'>
                            <div className='carimbo'><img src={carimbo} alt='carimbo de ensaio'></img></div>
                            <div className='assinatura'><img src={assinatura} alt='Assinatura Guiherme Bueno'></img></div>
                        </div>



                    </div>
                </div>
                <div className='rodape'>
                    <div>Orion S.A. - Rodovia Presidente Dutra, KM 135,1 CEP: 12247-004 - São José dos Campos - SP - Brasil</div>
                    <div> www.orionsa.com.br</div>
                    <div className='fr'>{tipos[tipoProduto].form}</div>
                </div>
            </div>
        </div>
    );
};

export default PdfLaudo;
