// Importe os componentes necessários
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './componentes/home.js';
import PdfLaudo from './componentes/pdf_laudo/pdfLaudo.js';
import BuscarLaudo from './componentes/BuscarLaudo.js';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';



// Defina suas rotas dentro do componente App
function App() {
  useEffect(() => {
    ReactGA.initialize("G-0V8YLC78PF"); // Substitua pela sua ID de acompanhamento
    ReactGA.send("pageview"); // Para enviar a visualização de página inicial
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/certificado/:numser" element={<PdfLaudo />} />

         <Route path="/laudo" element={<BuscarLaudo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
