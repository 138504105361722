import"../css/biblioteca.css";
import { FaNewspaper,FaBook } from "react-icons/fa6";
import { TbSquareLetterE, TbSquareLetterL  } from "react-icons/tb";


function Bibliotecas() {
    return (
      <div className="geralBiblioteca">
      <div className='Biblioteca'>
        <div className="item"> <FaNewspaper className="icon"/> 
        <span>Laudos Técnicos</span>
        </div>
        <div className="item"> <FaBook className="icon" />
        <span>Laudos Biblioteca</span>
        </div>
        <div className="item"> <TbSquareLetterE className="icon" /> 
        <span>Proc. de Segurança Enel</span>
        </div>
        <div className="item"> <TbSquareLetterL className="icon" /> 
        <span>Proc. de Segurança Light</span>
        </div>
        
      </div>
      </div>
    );
  }
  
  export default Bibliotecas;
