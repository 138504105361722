import React from 'react';
import '../css/app.css';
import BuscarLaudo from "./BuscarLaudo.js";
import Banner from "./Banner.js"
import Superior from "./Superior.js";
import Bibliotecas from './biblioteca.js';

function Home() {
  return (
    <div className="App">
      <div className='Superior'> <Superior /></div>  
      <div className='BannerApp'> <Banner /></div>
      <div className='Bibliotecas'> <Bibliotecas /></div>
      <div className='Laudo'><BuscarLaudo /></div>

      
    </div>
  );
}

export default Home;