// consultaApi.js
import api from './api'; // Ajuste o caminho se necessário

const Verificar = async (inputBusca, selectedOption) => {
  if (inputBusca === '') {
    alert('Preencha o número de Série do EPI ou Laudo');
    return null;
  }
  try {
    let busca;
    if (selectedOption === 'serieEpi') {
      busca = inputBusca;
    } else {
      busca = 'of/' + inputBusca;
    }
    const response = await api.get(busca);
    let data = response.data;

    if (!Array.isArray(data)) {
      data = [data];
    }

    return data;
  } catch (error) {
    const errorMessage = error.response && error.response.status === 404
      ? "Não existem dados para o número fornecido."
      : "Falha na conexão com a API.";
    alert(errorMessage);
    return null;
  }
};

export default Verificar;
